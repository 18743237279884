import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Subfooter from "../components/subfooter";
import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";
import Block from "../components/block";
import HeroTeam from "../components/heroTeam";
import Img from "gatsby-image";

function ServicePage({ location, data }) {
  const content = data.servicePage.frontmatter;

  return (
    <Layout location={location}>
      <SEO keywords={[`bloom`, `bloom works`]} title="Bloom Works" />
      <HeroTeam
        className={`bloom-pt-hero lg:bloom-pt-heroXL`}
        text={content.hero.heroText}
      />
      {content.blocks &&
        content.blocks.length > 0 &&
        content.blocks.map(block => {
          let contentHTML = remark()
            .use(recommended)
            .use(remarkHtml)
            .processSync(block.content)
            .toString();

          return (
            <Block
              className={`${block.theme || `bloom-bg-white`}`}
              key={block.title}
            >
              <div className={`bloom-mb-5 bloom-flex`}>
                <h2
                  className={`bloom-text-4xl bloom-uppercase bloom-font-bold bloom-border-b-4 bloom-border-green`}
                >
                  {block.title}
                </h2>
              </div>
              <div
                className={`bloom-prose`}
                dangerouslySetInnerHTML={{ __html: contentHTML }}
              />
            </Block>
          );
        })}
      {/* <Block>
				<div
					className={`bloom-flex bloom-flex-col md:bloom-flex-row bloom-flex-wrap bloom-mx-2 bloom-py-6 bloom-items-center`}>
					<div className='bloom-pr-0 md:bloom-pr-8 bloom-mb-2 sm:bloom-mb-0 bloom-w-1/5 md:bloom-w-2/12'>
						<Img fluid={content?.contracting?.image?.childImageSharp?.fluid} />
					</div>
					<div className='bloom-px-2 bloom-mb-2 sm:bloom-mb-0 bloom-w-full md:bloom-w-3/4'>
						<h2 className='bloom-text-3xl bloom-my-0'>Contracting</h2>
						<p className='bloom-text-lg bloom-mb-4 md:bloom-mb-2'>
							{content.contracting.description}
						</p>
						<a
							className='bloom-inline-block sm:bloom-block bloom-w-full md:bloom-w-2/5 bloom-mt-4 bloom-bg-green bloom-border-none bloom-text-white bloom-text-center bloom-p-5 bloom-leading-tight bloom-font-bold hover:bloom-bg-white hover:bloom-text-green'
							href='https://bloomworks.digital/contracting'>
							{content.contracting.ctaTitle}
						</a>
					</div>
				</div>
			</Block> */}
      <Subfooter></Subfooter>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ServiceTemplate {
    servicePage: markdownRemark(
      frontmatter: { templateKey: { eq: "services" } }
    ) {
      html
      frontmatter {
        title
        subtitle
        blocks {
          title
          content
          theme
        }
        hero {
          heroText
        }
        contracting {
          ctaTitle
          image {
            childImageSharp {
              fluid(maxWidth: 800, maxHeight: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          description
        }
      }
    }
  }
`;

export default ServicePage;
